(function($){
    const fn = $(".comp_questionnaire");

    if (fn.length) {
        const actions = {
          toggle_other: ".js-toggle-other"
        };

        fn.on("change", `${actions.toggle_other} .part_ui_checkbox input, ${actions.toggle_other} .part_ui_radio input`, function(){
           let elm = $(this);
           if (elm.prop("checked") === true) {
               elm.closest(actions.toggle_other).find(".part_ui_input").fadeIn(0).find("input, textarea").trigger("focus");
           } else {
               elm.closest(actions.toggle_other).find(".part_ui_input").fadeOut(0).find("input, textarea").off("focus");
           }
        });
    }
})(jQuery);